import React, { useState } from 'react';
import Header from './Header.js';

import homepage_section1 from './Assets/homepage_section1.jpg';
import homepage_section2 from './Assets/homepage_section2.jpg';
import homepage_section3 from './Assets/homepage_section3.jpg';
import homepage_footerandroid from './Assets/homepage_footerandroid.svg';

import './HomePage.css';

function Homepage({ state, setState }) {
    const [paymentoption, setPaymentoption] = useState(1);

    const [option, setOption] = useState(null);

    const handleBasicPlan = () => {
        setOption(<BasicPlan />);
    }

    const handleDemo = () => {
        setOption(<Demo />);
    }

    const startDemo = () => {
        setState(state => ({
            ...state,
            user: {
                userid: '669b5d6facee2',
                usertoken: '1fdd91bb65550a8b03306c8f1e43b59e',
                useremail: 'demouser@demo.com',
                username: 'demo_user',
                usertype: '0',
            }
        }))
    }

    function BasicPlan() {
        return (
            <div className='homepage_modal'>
                <div className='homepage_modaloverlay'>
                    <div className='homepage_modalcontent'>
                        <div className='homepage_modalclosebuttoncontainer'>
                            <div className='homepage_modalclosebutton' onClick={() => setOption(null)}>X</div>
                        </div>
                        <div className='homepage_modaltitle'>Basic Plan</div>
                        <p className='homepage_modaldescription'>We are only accepting Cash Payments at the moment.</p>
                    </div>
                </div>
            </div>
        )
    }

    function Demo() {
        return (
            <div className='homepage_modal'>
                <div className='homepage_modaloverlay'>
                    <div className='homepage_modalcontent'>
                        <div className='homepage_modalclosebuttoncontainer'>
                            <div className='homepage_modalclosebutton' onClick={() => setOption(null)}>X</div>
                        </div>
                        <div className='homepage_modaltitle'>Demo Account</div>
                        <p className='homepage_modaldescription'>Building XYZ 1344</p>
                        <button className='homepage_modalbutton' onClick={startDemo}>Start Demo</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='homepage'>
            <Header state={state} setState={setState} />

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <h1 className='homepage_title'>Property and Facilities Management Software</h1>
                    <p className='homepage_description'>
                        Our software offers comprehensive solutions for managing properties and facilities in the cloud.
                        With our platform, you can streamline property management tasks, optimize facility operations,
                        and enhance resident experiences.
                    </p>
                </div>
                <div className='homepage_sectionright'>
                    <div className='homepage_paymentplans'>
                        <div className="homepage_paymentplan">
                            <h3>Basic Plan</h3>
                            <select className='homepage_paymentplanselect' value={paymentoption} onChange={(e) => setPaymentoption(e.target.value)}>
                                <option value={1}>Monthly</option>
                                <option value={12}>Yearly</option>
                            </select>
                            {paymentoption == 1 && <p className="homepage_paymentplanprice">$10/Month</p>}
                            {paymentoption == 12 && <p className="homepage_paymentplanprice">$110/Year</p>}
                            <ul className='homepage_paymentplanfeatures'>
                                <li>Multi-user Access</li>
                                <li>User Permissions</li>
                                <li>Property Management Accounting</li>
                                <li>Facilities Management</li>
                            </ul>
                            <button className='homepage_planbutton' onClick={handleBasicPlan}>Basic Plan</button>
                            <button className='homepage_demobutton' onClick={handleDemo}>Demo Building XYZ</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <h1 className='homepage_title'>Transform Your Facilities with Smart Solutions</h1>
                    <p className='homepage_description'>Empower your buildings with cutting-edge automation, IoT technologies, and expert facilities management services.</p>
                    <h1 className='homepage_title'>Pioneers in Intelligent Building Solutions</h1>
                    <p className='homepage_description'>With years of experience, we specialize in creating smarter, safer, and more efficient environments.</p>
                </div>
                <div className='homepage_sectionright'>
                    <img className='homepage_image' src={homepage_section1} alt="Smart Solutions" />
                </div>
            </div>

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <img className='homepage_image' src={homepage_section2} alt="Smart Solutions" />
                </div>
                <div className='homepage_sectionright'>
                    <h1 className='homepage_title'>Building Automation</h1>
                    <p className='homepage_description'>Seamlessly integrate HVAC, lighting, and security systems.</p>
                    <h1 className='homepage_title'>IoT Transformation</h1>
                    <p className='homepage_description'>Deploy smart sensors for predictive maintenance and AI-driven decisions.</p>
                    <h1 className='homepage_title'>Facilities Management</h1>
                    <p className='homepage_description'>Streamline operations with modern tools and ensure regulatory compliance.</p>
                </div>
            </div>

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <h1 className='homepage_title'>Industries We Serve</h1>
                    <ul>
                        <li>Corporate Offices: Enhance employee comfort and reduce energy costs.</li>
                        <li>Healthcare Facilities: Secure and clean environments.</li>
                        <li>Residential Complexes: Improve tenant experiences with smart homes.</li>
                        <li>Industrial Sites: Optimize production and reduce inefficiencies.</li>
                    </ul>
                </div>
                <div className='homepage_sectionright'>
                    <img className='homepage_image' src={homepage_section3} alt="Smart Solutions" />
                </div>
            </div>

            <div className='homepage_footer'>
                <div className='homepage_sectionleft'>
                    <div className='homepage_footertitle'>Contact Us</div>
                    <div className='homepage_footercontact'>Email: propertydynamicsfm@gmail.com</div>
                </div>
                <div className='homepage_sectionright'>
                    <div className='homepage_footerandroid'>
                        <img src={homepage_footerandroid} width={100} alt="Footer Android Icon" />
                    </div>
                    Facebook
                    LinkedIn
                    Instagram
                </div>
            </div>

            {option}

        </div>
    );
}

export default Homepage;