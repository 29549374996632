import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Title from './Title.js';
import modal_closebutton from './Assets/modal_closebutton.png';

import './Modal.css';

//revision 20240928

const Modal = ({ state, setState }) => {
  const location = useLocation();
  let modalOverlayClass;
  let modalContentClass;

  switch (state.modaltype) {
    case 'large':
      modalOverlayClass = 'modal_overlay_large';
      modalContentClass = 'modal_content_large';
      break;
    case 'small':
      modalOverlayClass = 'modal_overlay_small';
      modalContentClass = 'modal_content_small';
      break;
    case 'mediagallery':
      modalOverlayClass = 'modal_overlay_mediagallery';
      modalContentClass = 'modal_content_mediagallery';
      break;
    default:
      modalOverlayClass = 'modal_overlay_home';
      modalContentClass = 'modal_content_home';
  }

  useEffect(() => {
    if (state.modalopen) {
      document.body.classList.add('modal_removeoverflow');
    }
    else {
      document.body.classList.remove('modal_removeoverflow');
    }

    return () => {
      document.body.classList.remove('modal_removeoverflow');
    };
  }, [state.modalopen, location]);

  if (!state.modalopen) return null;

  const handleClose = () => {
    setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null }));
  }

  return (
    <div className={modalOverlayClass} onClick={handleClose}>
      <div className={modalContentClass} onClick={(e) => e.stopPropagation()}>
        <Title text={state.modaltitle} />
        < div className="modal_closebutton" onClick={handleClose}>
          <img className="modal_closebuttonimage" src={modal_closebutton} alt="Close" />
        </div>
        {state.modalcontent}
      </div>
    </div >
  );
};

export default Modal;