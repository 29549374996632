import { findNearestExchangeRate } from "./currency.js";
import { naturalCompare } from "./textparse.js";
import moment from "moment";

export function getActualBudget(incomegroups, incomeaccounts, expensegroups, expenseaccounts, journalentries, transactions, fromts, tots, cashaccount, subaccount, exchangerates, currencies, basecurrency) {
  const resultArray = [];
  const baseCurrencySymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;

  incomegroups.sort((a, b) => a.orderid - b.orderid);
  incomeaccounts.sort((a, b) => a.orderid - b.orderid);
  expensegroups.sort((a, b) => a.orderid - b.orderid);
  expenseaccounts.sort((a, b) => a.orderid - b.orderid);

  let incomegroupedsectiontotalactual = 0;
  let incomegroupedsectiontotalbudget = 0;
  let incomeungroupedsectiontotalactual = 0;
  let incomeungroupedsectiontotalbudget = 0;

  let expensegroupedsectiontotalactual = 0;
  let expensegroupedsectiontotalbudget = 0;
  let expenseungroupedsectiontotalactual = 0;
  let expenseungroupedsectiontotalbudget = 0;

  resultArray.push({
    name: "Income",
    actual: "",
    budget: "",
    type: "section"
  });

  incomegroups.forEach((group) => {
    resultArray.push({
      name: group.name,
      actual: "",
      budget: "",
      type: "group",
    });

    const groupIncomeAccounts = incomeaccounts.filter((account) => account.groupid === group.id);
    let incomegroupedgrouptotalactual = 0;
    let incomegroupedgrouptotalbudget = 0;
    groupIncomeAccounts.forEach((account) => {
      let sum = 0;
      const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;

      journalentries.forEach((entry) => {
        if (entry.ts >= fromts &&
          entry.ts <= tots &&
          (subaccount == 0 || entry.subaccount === subaccount)
        ) {
          entry.rows.forEach((row) => {
            if (row.accountid === account.id) {
              if (account.currencyid === entry.currencyid) {
                const convertedDebit = parseFloat(row.debit);
                const convertedCredit = parseFloat(row.credit);
                sum -= convertedDebit;
                sum += convertedCredit;
              }
              else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                const exchangerate = NearestExchangeRate.exchangerate;
                const convertedDebit = parseFloat(row.debit) * exchangerate;
                const convertedCredit = parseFloat(row.credit) * exchangerate;
                sum -= convertedDebit;
                sum += convertedCredit;
              }
            }
          });
        }
      });

      if (account.currencyid !== basecurrency) {
        const groupNearestExchangeRate = findNearestExchangeRate(exchangerates, moment().unix(), account.currencyid, basecurrency);
        const groupexchangerate = groupNearestExchangeRate.exchangerate;
        const convertedActual = parseFloat(sum) * groupexchangerate;
        const convertedBudget = parseFloat(account.budget) * groupexchangerate;
        incomegroupedgrouptotalactual += convertedActual;
        incomegroupedgrouptotalbudget += convertedBudget;
      }
      else {
        incomegroupedgrouptotalactual += sum;
        incomegroupedgrouptotalbudget += account.budget;
      }

      resultArray.push({
        accountid: account.id,
        name: account.name,
        currency: currencySymbol,
        actual: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
        budget: account.budget.toLocaleString("en-US"),
        percentage: account.budget === 0 ? "0 %" : sum === 0 ? "0 %" : ((sum / account.budget) * 100).toFixed(2) + " %",
        section: "income",
        type: "account",
      });
    });

    incomegroupedsectiontotalactual = incomegroupedsectiontotalactual + incomegroupedgrouptotalactual;
    incomegroupedsectiontotalbudget = incomegroupedsectiontotalbudget + incomegroupedgrouptotalbudget;

    resultArray.push({
      name: "Total - " + group.name,
      currency: baseCurrencySymbol,
      actual: incomegroupedgrouptotalactual.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(incomegroupedgrouptotalactual) ? 0 : 2, maximumFractionDigits: 2 }),
      budget: incomegroupedgrouptotalbudget.toLocaleString("en-US"),
      percentage: incomegroupedgrouptotalbudget === 0 ? "0 %" : ((incomegroupedgrouptotalactual / incomegroupedgrouptotalbudget) * 100).toFixed(2) + " %",
      type: "grouptotal",
    });
  });

  const ungroupedIncomeAccounts = incomeaccounts.filter((account) => !account.groupid);
  if (ungroupedIncomeAccounts.length) {
    resultArray.push({
      name: "Ungrouped Accounts",
      actual: "",
      budget: "",
      type: "group",
    });

    let incomeungroupedgrouptotalactual = 0;
    let incomeungroupedgrouptotalbudget = 0;
    ungroupedIncomeAccounts.forEach((account) => {
      let sum = 0;
      const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;

      journalentries.forEach((entry) => {
        if (entry.ts >= fromts &&
          entry.ts <= tots &&
          (subaccount == 0 || entry.subaccount === subaccount)
        ) {
          entry.rows.forEach((row) => {
            if (row.accountid === account.id) {
              if (account.currencyid === entry.currencyid) {
                const convertedDebit = parseFloat(row.debit);
                const convertedCredit = parseFloat(row.credit);
                sum -= convertedDebit;
                sum += convertedCredit;
              }
              else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                const exchangerate = NearestExchangeRate.exchangerate;
                const convertedDebit = parseFloat(row.debit) * exchangerate;
                const convertedCredit = parseFloat(row.credit) * exchangerate;
                sum -= convertedDebit;
                sum += convertedCredit;
              }
            }
          });
        }
      });

      if (account.currencyid !== basecurrency) {
        const groupNearestExchangeRate = findNearestExchangeRate(exchangerates, moment().unix(), account.currencyid, basecurrency);
        const groupexchangerate = groupNearestExchangeRate.exchangerate;
        const convertedActual = parseFloat(sum) * groupexchangerate;
        const convertedBudget = parseFloat(account.budget) * groupexchangerate;
        incomeungroupedgrouptotalactual += convertedActual;
        incomeungroupedgrouptotalbudget += convertedBudget;
      }
      else {
        incomeungroupedgrouptotalactual += sum;
        incomeungroupedgrouptotalbudget += account.budget;
      }

      resultArray.push({
        name: account.name,
        currency: currencySymbol,
        actual: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
        budget: account.budget.toLocaleString("en-US"),
        percentage: account.budget === 0 ? "0 %" : sum === 0 ? "0 %" : ((sum / account.budget) * 100).toFixed(2) + " %",
        section: "income",
        type: "account",
      });
    });

    incomegroupedsectiontotalactual = incomegroupedsectiontotalactual + incomeungroupedgrouptotalactual;
    incomegroupedsectiontotalbudget = incomegroupedsectiontotalbudget + incomeungroupedgrouptotalbudget;

    resultArray.push({
      name: "Total - Ungrouped Accounts",
      currency: baseCurrencySymbol,
      actual: incomeungroupedgrouptotalactual.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(incomeungroupedgrouptotalactual) ? 0 : 2, maximumFractionDigits: 2 }),
      budget: incomeungroupedgrouptotalbudget.toLocaleString("en-US"),
      percentage: incomeungroupedgrouptotalbudget === 0 ? "0 %" : ((incomeungroupedgrouptotalactual / incomeungroupedgrouptotalbudget) * 100).toFixed(2) + " %",
      type: "grouptotal",
    });
  }

  let incomesectiontotalactual = incomegroupedsectiontotalactual + incomeungroupedsectiontotalactual;
  let incomesectiontotalbudget = incomegroupedsectiontotalbudget + incomeungroupedsectiontotalbudget;

  resultArray.push({
    name: "Total",
    currency: baseCurrencySymbol,
    actual: incomesectiontotalactual.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(incomesectiontotalactual) ? 0 : 2, maximumFractionDigits: 2 }),
    budget: incomesectiontotalbudget.toLocaleString("en-US"),
    percentage: incomesectiontotalbudget === 0 ? "0 %" : ((incomesectiontotalactual / incomesectiontotalbudget) * 100).toFixed(2) + " %",
    section: "income",
    type: "sectiontotal",
  });

  resultArray.push({
    name: "Expenses",
    actual: "",
    budget: "",
    type: "section",
  });

  expensegroups.forEach((group) => {
    resultArray.push({
      name: group.name,
      actual: "",
      budget: "",
      type: "group",
    });

    const groupExpenseAccounts = expenseaccounts.filter((account) => account.groupid === group.id);
    let expensegroupedgrouptotalactual = 0;
    let expensegroupedgrouptotalbudget = 0;
    groupExpenseAccounts.forEach((account) => {
      let sum = 0;
      const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;

      journalentries.forEach((entry) => {
        if (
          entry.ts >= fromts &&
          entry.ts <= tots &&
          (subaccount == 0 || entry.subaccount === subaccount)
        ) {
          entry.rows.forEach((row) => {
            if (row.accountid === account.id) {
              const NearestExchangeRate = findNearestExchangeRate(
                exchangerates,
                entry.ts,
                row.currencyid,
                account.currencyid
              );
              const exchangerate = NearestExchangeRate.exchangerate;
              const convertedDebit = parseFloat(row.debit) * exchangerate;
              const convertedCredit = parseFloat(row.credit) * exchangerate;
              sum += convertedDebit;
              sum -= convertedCredit;
            }
          });
        }
      });

      transactions.forEach((transaction) => {
        if (
          transaction.to === account.id &&
          transaction.ts >= fromts &&
          transaction.ts <= tots &&
          (cashaccount == 0 || transaction.from === cashaccount) &&
          (subaccount == 0 || transaction.subaccount === subaccount)
        ) {
          if (account.currencyid === transaction.currencyid) {
            sum += parseFloat(transaction.amount);
          }
          else {
            const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
            const exchangerate = NearestExchangeRate.exchangerate;
            const convertedAmount = parseFloat(transaction.amount) * exchangerate;
            sum += convertedAmount;
          }
        }
      });

      if (account.currencyid !== basecurrency) {
        const groupNearestExchangeRate = findNearestExchangeRate(exchangerates, moment().unix(), account.currencyid, basecurrency);
        const groupexchangerate = groupNearestExchangeRate.exchangerate;
        const convertedActual = parseFloat(sum) * groupexchangerate;
        const convertedBudget = parseFloat(account.budget) * groupexchangerate;
        expensegroupedgrouptotalactual += convertedActual;
        expensegroupedgrouptotalbudget += convertedBudget;
      }
      else {
        expensegroupedgrouptotalactual += sum;
        expensegroupedgrouptotalbudget += account.budget;
      }

      resultArray.push({
        accountid: account.id,
        name: account.name,
        currency: currencySymbol,
        actual: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
        budget: account.budget.toLocaleString("en-US"),
        percentage: account.budget === 0 ? "0 %" : sum === 0 ? "0 %" : ((sum / account.budget) * 100).toFixed(2) + " %",
        section: "expense",
        type: "account",
      });
    });

    expensegroupedsectiontotalactual = expensegroupedsectiontotalactual + expensegroupedgrouptotalactual;
    expensegroupedsectiontotalbudget = expensegroupedsectiontotalbudget + expensegroupedgrouptotalbudget;

    resultArray.push({
      name: "Total - " + group.name,
      currency: baseCurrencySymbol,
      actual: expensegroupedgrouptotalactual.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(expensegroupedgrouptotalactual) ? 0 : 2, maximumFractionDigits: 2 }),
      budget: expensegroupedgrouptotalbudget.toLocaleString("en-US"),
      percentage: expensegroupedgrouptotalbudget === 0 ? "0 %" : ((expensegroupedgrouptotalactual / expensegroupedgrouptotalbudget) * 100).toFixed(2) + " %",
      type: "grouptotal",
    });
  });

  const ungroupedExpenseAccounts = expenseaccounts.filter((account) => !account.groupid);
  if (ungroupedExpenseAccounts.length) {
    resultArray.push({
      name: "Ungrouped Accounts",
      actual: "",
      budget: "",
      type: "group",
    });

    let expenseungroupedgrouptotalactual = 0;
    let expenseungroupedgrouptotalbudget = 0;
    ungroupedExpenseAccounts.forEach((account) => {
      let sum = 0;
      const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;

      journalentries.forEach((entry) => {
        if (
          entry.ts >= fromts &&
          entry.ts <= tots &&
          (subaccount == 0 || entry.subaccount === subaccount)
        ) {
          entry.rows.forEach((row) => {
            if (row.accountid === account.id) {
              if (account.currencyid === entry.currencyid) {
                const convertedDebit = parseFloat(row.debit);
                const convertedCredit = parseFloat(row.credit);
                sum += convertedDebit;
                sum -= convertedCredit;
              }
              else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                const exchangerate = NearestExchangeRate.exchangerate;
                const convertedDebit = parseFloat(row.debit) * exchangerate;
                const convertedCredit = parseFloat(row.credit) * exchangerate;
                sum += convertedDebit;
                sum -= convertedCredit;
              }
            }
          });
        }
      });

      transactions.forEach((transaction) => {
        if (
          transaction.to === account.id &&
          transaction.ts >= fromts &&
          transaction.ts <= tots &&
          (cashaccount == 0 || transaction.from === cashaccount) &&
          (subaccount == 0 || transaction.subaccount === subaccount)
        ) {
          if (account.currencyid === transaction.currencyid) {
            sum += parseFloat(transaction.amount);
          }
          else {
            const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
            const exchangerate = NearestExchangeRate.exchangerate;
            const convertedAmount = parseFloat(transaction.amount) * exchangerate;
            sum += convertedAmount;
          }
        }
      });

      if (account.currencyid !== basecurrency) {
        const groupNearestExchangeRate = findNearestExchangeRate(exchangerates, moment().unix(), account.currencyid, basecurrency);
        const groupexchangerate = groupNearestExchangeRate.exchangerate;
        const convertedActual = parseFloat(sum) * groupexchangerate;
        const convertedBudget = parseFloat(account.budget) * groupexchangerate;
        expenseungroupedgrouptotalactual += convertedActual;
        expenseungroupedgrouptotalbudget += convertedBudget;
      }
      else {
        expenseungroupedgrouptotalactual += sum;
        expenseungroupedgrouptotalbudget += account.budget;
      }

      resultArray.push({
        accountid: account.id,
        name: account.name,
        currency: currencySymbol,
        actual: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
        budget: account.budget.toLocaleString("en-US"),
        percentage: account.budget === 0 ? "0 %" : sum === 0 ? "0 %" : ((sum / account.budget) * 100).toFixed(2) + " %",
        section: "expense",
        type: "account",
      });
    });

    expensegroupedsectiontotalactual = expensegroupedsectiontotalactual + expenseungroupedgrouptotalactual;
    expensegroupedsectiontotalbudget = expensegroupedsectiontotalbudget + expenseungroupedgrouptotalbudget;

    resultArray.push({
      name: "Total - Ungrouped Accounts",
      currency: baseCurrencySymbol,
      actual: expenseungroupedgrouptotalactual.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(expenseungroupedgrouptotalactual) ? 0 : 2, maximumFractionDigits: 2 }),
      budget: expenseungroupedgrouptotalbudget,
      percentage: expenseungroupedgrouptotalbudget === 0 ? "0 %" : ((expenseungroupedgrouptotalactual / expenseungroupedgrouptotalbudget) * 100).toFixed(2) + " %",
      type: "grouptotal",
    });
  }

  let expensesectiontotalactual = expensegroupedsectiontotalactual + expenseungroupedsectiontotalactual;
  let expensesectiontotalbudget = expensegroupedsectiontotalbudget + expenseungroupedsectiontotalbudget;

  resultArray.push({
    name: "Total",
    currency: baseCurrencySymbol,
    actual: expensesectiontotalactual.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(expensesectiontotalactual) ? 0 : 2, maximumFractionDigits: 2 }),
    budget: expensesectiontotalbudget.toLocaleString("en-US"),
    percentage: expensesectiontotalbudget === 0 ? "0 %" : ((expensesectiontotalactual / expensesectiontotalbudget) * 100).toFixed(2) + " %",
    section: "expense",
    type: "sectiontotal",
  });

  return resultArray;
}

export function getCashFlowData(cashaccounts, expenseaccounts, transactions, fromts, tots, cashaccount, subaccount, exchangerates, currencies, basecurrency) {
  const resultArray = [];
  const currencySymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;

  resultArray.push({
    name: "Cash Inflows",
    currency: "",
    amount: "",
    type: "section",
  });

  let sumReceipts = 0;

  transactions.forEach((transaction) => {
    if (
      transaction.type === "receipt" &&
      transaction.ts >= fromts &&
      transaction.ts <= tots &&
      (cashaccount == 0 || transaction.to === cashaccount) &&
      (subaccount == 0 || transaction.subaccount === subaccount)
    ) {
      let sum = 0;
      if (basecurrency === transaction.currencyid) {
        sum += parseFloat(transaction.amount);
      }
      else {
        const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
        const exchangerate = NearestExchangeRate.exchangerate;
        const convertedAmount = parseFloat(transaction.amount) * exchangerate;
        sum += convertedAmount;
      }
      sumReceipts += sum;
    }
  });

  resultArray.push({
    name: "Receipts",
    currency: currencySymbol,
    amount: sumReceipts,
    type: "accountinflows",
  });

  resultArray.push({
    name: "Less: Cash Outflows",
    currency: "",
    amount: "",
    type: "section",
  });

  let sumPayments = 0;

  expenseaccounts.sort((a, b) => naturalCompare(a.name, b.name));

  expenseaccounts.forEach((account) => {
    let sum = 0;

    transactions.forEach((transaction) => {
      if (
        transaction.type === "payment" &&
        transaction.to === account.id &&
        transaction.ts >= fromts &&
        transaction.ts <= tots &&
        (cashaccount == 0 || transaction.from === cashaccount) &&
        (subaccount == 0 || transaction.subaccount === subaccount)
      ) {
        if (basecurrency === transaction.currencyid) {
          sum += parseFloat(transaction.amount);
        }
        else {
          const NearestExchangeRate = findNearestExchangeRate(
            exchangerates,
            transaction.ts,
            transaction.currencyid,
            basecurrency
          );
          const exchangerate = NearestExchangeRate.exchangerate;
          const convertedAmount = parseFloat(transaction.amount) * exchangerate;
          sum += convertedAmount;
        }
      }
    });

    resultArray.push({
      id: account.id,
      name: account.name,
      currency: currencySymbol,
      amount: sum,
      type: "accountoutflows",
    });

    sumPayments += sum;
  });

  resultArray.push({
    name: "Total payments",
    currency: currencySymbol,
    amount: sumPayments,
    type: "grouptotal",
  });

  const netChange = sumReceipts - sumPayments;

  if (netChange > 0) {
    resultArray.push({
      name: "Net increase in cash",
      currency: currencySymbol,
      amount: netChange,
      type: "sectiontotal",
    });
  }
  else {
    resultArray.push({
      name: "Net decrease in cash",
      currency: currencySymbol,
      amount: netChange,
      type: "sectiontotal",
    });
  }

  let cashAtBeginning = 0;

  cashaccounts.forEach((account) => {
    if (basecurrency === account.currencyid) {
      cashAtBeginning += parseFloat(account.openingbalance);
    }
    else {
      const NearestExchangeRate = findNearestExchangeRate(exchangerates, fromts, account.currencyid, basecurrency);
      const exchangerate = NearestExchangeRate.exchangerate;
      const convertedAmount = parseFloat(account.openingbalance) * exchangerate;
      cashAtBeginning += convertedAmount;
    }
  });

  transactions.forEach((transaction) => {
    if (transaction.ts < fromts) {
      let amount = parseFloat(transaction.amount);
      if (basecurrency !== transaction.currencyid) {
        const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
        const exchangerate = NearestExchangeRate.exchangerate;
        const convertedAmount = parseFloat(transaction.amount) * exchangerate;
        amount = convertedAmount;
      }
      if (transaction.type === "payment") {
        cashAtBeginning -= amount;
      }
      else if (transaction.type === "receipt") {
        cashAtBeginning += amount;
      }
    }
  });

  resultArray.push({
    name: "Cash at beginning of the period",
    currency: currencySymbol,
    amount: cashAtBeginning,
    type: "sectiontotal",
  });

  const cashAtEnd = cashAtBeginning + netChange;

  resultArray.push({
    name: "Cash at the end of the period",
    currency: currencySymbol,
    amount: cashAtEnd,
    type: "sectiontotal",
  });

  return resultArray;
}

export function getReceipts(clients, transactions, fromts, tots, cashaccount, subaccount, cashaccounts, subaccounts, exchangerates, currencies, basecurrency) {
  const resultArray = [];
  const selectedsubaccount = subaccounts.find((sub) => sub.id === subaccount);
  let currencysymbol;

  if (selectedsubaccount) {
    currencysymbol = currencies.find((currency) => currency.id == selectedsubaccount.currencyid)?.symbol;
  } else {
    currencysymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;
  }

  let totalReceipts = 0;

  transactions.forEach((transaction) => {
    if (transaction.type === "receipt" && transaction.ts >= fromts && transaction.ts <= tots) {
      if (cashaccount == 0 || transaction.to == cashaccount) {
        if (subaccount == 0 || transaction.subaccount == subaccount) {
          const clientname = clients.find((client) => client.id === transaction.from)?.name;
          const cashaccountname = cashaccounts.find((cash) => cash.id === transaction.to)?.name || "No cash account";
          const subaccountname = subaccounts.find((sub) => sub.id === transaction.subaccount)?.name || "No subaccount";
          if (transaction.currencyid === selectedsubaccount?.currencyid) {
            const date = moment.unix(transaction.ts).format("D/MMM/YYYY");
            totalReceipts += parseFloat(transaction.amount);

            resultArray.push({
              ts: transaction.ts,
              date: date,
              clientname: clientname,
              name: transaction.name,
              cashaccount: cashaccountname,
              subaccount: subaccountname,
              currency: currencysymbol,
              amount: parseFloat(transaction.amount).toLocaleString("en-US"),
              type: "account",
            });
          } else {
            const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
            const exchangerate = NearestExchangeRate.exchangerate;
            const convertedAmount = parseFloat(transaction.amount) * exchangerate;
            const date = moment.unix(transaction.ts).format("D/MMM/YYYY");
            totalReceipts += convertedAmount;

            resultArray.push({
              ts: transaction.ts,
              date: date,
              clientname: clientname,
              name: transaction.name,
              cashaccount: cashaccountname,
              subaccount: subaccountname,
              currency: currencysymbol,
              amount: convertedAmount.toLocaleString("en-US"),
              type: "account",
            });
          }
        }
      }
    }
  });

  resultArray.sort((a, b) => a.ts - b.ts);

  resultArray.push({
    date: "",
    name: "Total receipts",
    currency: currencysymbol,
    amount: totalReceipts.toLocaleString("en-US"),
    type: "total",
  });

  return resultArray;
}

export function getExpenses(
  suppliers,
  expenseaccounts,
  transactions,
  fromts,
  tots,
  cashaccount,
  subaccount,
  cashaccounts,
  subaccounts,
  exchangerates,
  currencies,
  basecurrency
) {
  const resultArray = [];
  const currencySymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;

  let totalExpenses = 0;

  transactions.forEach((transaction) => {
    if (transaction.type === "payment" && transaction.ts >= fromts && transaction.ts <= tots) {
      if (cashaccount == 0 || transaction.from == cashaccount) {
        if (subaccount == 0 || transaction.subaccount == subaccount) {
          const suppliername = suppliers.find((supplier) => supplier.id === transaction.beneficiaryid)?.name;
          const cashaccountname = cashaccounts.find((cash) => cash.id === transaction.from)?.name || "No cash account";
          const subaccountname = subaccounts.find((sub) => sub.id === transaction.subaccount)?.name || "No subaccount";
          if (basecurrency === transaction.currencyid) {
            const date = moment.unix(transaction.ts).format("D/MMM/YYYY");
            totalExpenses += parseFloat(transaction.amount);
            resultArray.push({
              ts: transaction.ts,
              date: date,
              suppliername: suppliername,
              name: transaction.name,
              cashaccount: cashaccountname,
              subaccount: subaccountname,
              currency: currencySymbol,
              amount: parseFloat(transaction.amount).toLocaleString("en-US"),
              type: "account",
            });
          }
          else {
            const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
            const exchangerate = NearestExchangeRate.exchangerate;
            const convertedAmount = parseFloat(transaction.amount) * exchangerate;
            const date = moment.unix(transaction.ts).format("D/MMM/YYYY");
            totalExpenses += convertedAmount;

            resultArray.push({
              ts: transaction.ts,
              date: date,
              suppliername: suppliername,
              name: transaction.name,
              cashaccount: cashaccountname,
              subaccount: subaccountname,
              currency: currencySymbol,
              amount: convertedAmount.toLocaleString("en-US"),
              type: "account",
            });
          }
        }
      }
    }
  });

  resultArray.sort((a, b) => a.ts - b.ts);

  resultArray.push({
    date: "",
    name: "Total Expenses",
    currency: currencySymbol,
    amount: totalExpenses.toLocaleString("en-US"),
    type: "total",
  });

  return resultArray;
}

export function getClientBalancesRow(clients, transactions, journalentries, fromts, tots, subaccounts, selectedsubaccountid, currencies, exchangerates, order) {
  const clientData = [];

  clients.forEach((client) => {
    const clientSubaccounts = {};

    transactions.forEach((transaction) => {
      if (transaction.ts >= fromts && transaction.ts <= tots) {
        if (transaction.from === client.id) {
          if (selectedsubaccountid == 0 || transaction.subaccount == selectedsubaccountid) {
            const subaccount = subaccounts.find(
              (sub) => sub.id === transaction.subaccount
            );
            const subaccountName = subaccount ? subaccount.name : "Default";
            const subaccountCurrencyid = subaccount ? subaccount.currencyid : "";
            const currencySymbol = currencies.find((currency) => currency.id == subaccountCurrencyid)?.symbol;
            const amount = parseFloat(transaction.amount);
            if (subaccountCurrencyid === transaction.currencyid) {
              clientSubaccounts[subaccountName] = {
                balance:
                  (clientSubaccounts[subaccountName]?.balance || 0) - amount,
                currencySymbol: currencySymbol,
              };
            }
            else {
              const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, subaccountCurrencyid);
              const exchangerate = NearestExchangeRate.exchangerate;
              const convertedAmount =
                parseFloat(transaction.amount) * exchangerate;
              clientSubaccounts[subaccountName] = {
                balance: (clientSubaccounts[subaccountName]?.balance || 0) - convertedAmount, currencySymbol: currencySymbol,
              };
            }
          }
        }
      }
    });

    journalentries.forEach((entry) => {
      if (entry.ts >= fromts && entry.ts <= tots) {
        entry.rows.forEach((row) => {
          if (row.accountid === client.id) {
            if (
              selectedsubaccountid == 0 ||
              entry.subaccount == selectedsubaccountid
            ) {
              const subaccount = subaccounts.find(
                (sub) => sub.id === entry.subaccount
              );
              const subaccountName = subaccount ? subaccount.name : "Default";
              const subaccountCurrencyid = subaccount
                ? subaccount.currencyid
                : "";
              const currencySymbol = currencies.find(
                (currency) => currency.id == subaccountCurrencyid
              )?.symbol;
              const amount = parseFloat(row.debit) - parseFloat(row.credit);
              if (subaccountCurrencyid === entry.currencyid) {
                clientSubaccounts[subaccountName] = {
                  balance:
                    (clientSubaccounts[subaccountName]?.balance || 0) + amount,
                  currencySymbol: currencySymbol,
                };
              } else {
                const NearestExchangeRate = findNearestExchangeRate(
                  exchangerates,
                  entry.ts,
                  entry.currencyid,
                  subaccountCurrencyid
                );
                const exchangerate = NearestExchangeRate.exchangerate;
                const convertedAmount = amount * exchangerate;
                clientSubaccounts[subaccountName] = {
                  balance:
                    (clientSubaccounts[subaccountName]?.balance || 0) +
                    convertedAmount,
                  currencySymbol: currencySymbol,
                };
              }
            }
          }
        });
      }
    });

    Object.entries(clientSubaccounts).forEach(
      ([subaccountName, { balance, currencySymbol }]) => {
        clientData.push({
          clientname: client.name,
          subaccountname: subaccountName,
          balance: balance,
          currencysymbol: currencySymbol,
        });
      }
    );
  });

  if (order === "balanceasc") clientData.sort((a, b) => a.balance - b.balance);
  if (order === "balancedesc") clientData.sort((a, b) => b.balance - a.balance);
  if (order === "nameasc") clientData.sort((a, b) => naturalCompare(a.clientname, b.clientname));
  if (order === "namedesc") clientData.sort((a, b) => naturalCompare(b.clientname, a.clientname));

  return clientData;
}

export function getClientBalancesColumn(clients, transactions, journalentries, fromts, tots, subaccounts, currencies, exchangerates, basecurrency, order) {
  const baseCurrencySymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;
  const result = {
    header: ["Name"],
    rows: [],
    footer: []
  };

  const clientData = [];
  const allSubaccountNames = new Set();

  clients.forEach((client) => {
    transactions.forEach((transaction) => {
      if (transaction.from === client.id) {
        const subaccount = subaccounts.find(sub => sub.id === transaction.subaccount);
        if (subaccount) {
          allSubaccountNames.add(subaccount);
        }
      }
    });
    journalentries.forEach((entry) => {
      entry.rows.forEach((row) => {
        if (row.accountid === client.id) {
          const subaccount = subaccounts.find(sub => sub.id === entry.subaccount);
          if (subaccount) {
            allSubaccountNames.add(subaccount);
          }
        }
      });
    });
  });

  let totals = {};

  clients.forEach((client) => {
    const clientSubaccounts = {};

    transactions.forEach((transaction) => {
      if (transaction.ts >= fromts && transaction.ts <= tots) {
        if (transaction.from === client.id) {
          const subaccount = subaccounts.find(sub => sub.id === transaction.subaccount);
          if (subaccount) {
            const subaccountId = subaccount.id;
            const subaccountCurrencyid = subaccount.currencyid;
            const currencySymbol = currencies.find(currency => currency.id == subaccountCurrencyid)?.symbol;
            const amount = parseFloat(transaction.amount);
            if (subaccountCurrencyid === transaction.currencyid) {
              clientSubaccounts[subaccountId] = {
                balance: (clientSubaccounts[subaccountId]?.balance || 0) - amount,
                currencyid: subaccountCurrencyid,
                currencysymbol: currencySymbol,
                subaccountid: subaccountId
              };
            }
            else {
              const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, subaccountCurrencyid);
              const exchangerate = NearestExchangeRate.exchangerate;
              const convertedAmount = amount * exchangerate;
              clientSubaccounts[subaccountId] = {
                balance: (clientSubaccounts[subaccountId]?.balance || 0) - convertedAmount,
                currencyid: subaccountCurrencyid,
                currencysymbol: currencySymbol,
                subaccountid: subaccountId
              };
            }
          }
        }
      }
    });

    journalentries.forEach((entry) => {
      if (entry.ts >= fromts && entry.ts <= tots) {
        entry.rows.forEach((row) => {
          if (row.accountid === client.id) {
            const subaccount = subaccounts.find(sub => sub.id === entry.subaccount);
            if (subaccount) {
              const subaccountId = subaccount.id;
              const subaccountCurrencyid = subaccount.currencyid;
              const currencySymbol = currencies.find(currency => currency.id == subaccountCurrencyid)?.symbol;
              const amount = parseFloat(row.debit) - parseFloat(row.credit);
              if (subaccountCurrencyid === entry.currencyid) {
                clientSubaccounts[subaccountId] = {
                  balance: (clientSubaccounts[subaccountId]?.balance || 0) + amount,
                  currencyid: subaccountCurrencyid,
                  currencysymbol: currencySymbol,
                  subaccountid: subaccountId
                };
              }
              else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, subaccountCurrencyid);
                const exchangerate = NearestExchangeRate.exchangerate;
                const convertedAmount = amount * exchangerate;
                clientSubaccounts[subaccountId] = {
                  balance: (clientSubaccounts[subaccountId]?.balance || 0) + convertedAmount,
                  currencyid: subaccountCurrencyid,
                  currencysymbol: currencySymbol,
                  subaccountid: subaccountId
                };
              }
            }
          }
        });
      }
    });

    const columns = [client.name];
    let totalBalance = 0;

    allSubaccountNames.forEach((subaccountObject) => {
      const subaccount = clientSubaccounts[subaccountObject.id];
      const subaccountCurrencysymbol = currencies.find((currency) => currency.id == subaccountObject.currencyid)?.symbol;
      if (subaccount) {
        const balance = subaccount.balance;
        const currencySymbol = subaccount.currencysymbol;
        columns.push(currencySymbol + ' ' + balance.toLocaleString("en-US"));

        if (basecurrency == subaccount.currencyid) {
          totalBalance += balance;
        }
        else {
          const NearestExchangeRate = findNearestExchangeRate(exchangerates, 0, subaccount.currencyid, basecurrency);
          const exchangerate = NearestExchangeRate.exchangerate;
          totalBalance += balance * exchangerate;
        }
        totals[subaccountObject.name] = (totals[subaccountObject.name] || 0) + balance;
      }
      else {
        columns.push(subaccountCurrencysymbol + ' ' + 0);
      }
    });

    columns.push(baseCurrencySymbol + ' ' + totalBalance.toLocaleString("en-US"));
    totals["Total"] = (totals["Total"] || 0) + totalBalance;

    clientData.push({ clientname: client.name, columns: columns, totalbalance: totalBalance });

  });

  allSubaccountNames.forEach((subaccountObject) => {
    result.header.push(subaccountObject.name);
  });
  result.header.push("Total");

  if (order === "balanceasc") clientData.sort((a, b) => a.totalbalance - b.totalbalance);
  if (order === "balancedesc") clientData.sort((a, b) => b.totalbalance - a.totalbalance);
  if (order === "nameasc") clientData.sort((a, b) => naturalCompare(a.clientname, b.clientname));
  if (order === "namedesc") clientData.sort((a, b) => naturalCompare(b.clientname, a.clientname));

  result.rows = clientData;

  const footerRow = ["Total"];
  allSubaccountNames.forEach((subaccountObject) => {
    const currencySymbol = currencies.find((currency) => currency.id === subaccountObject.currencyid)?.symbol;
    const totalBalance = totals[subaccountObject.name] || 0;
    footerRow.push(currencySymbol + ' ' + totalBalance.toLocaleString("en-US"));
  });
  footerRow.push(baseCurrencySymbol + ' ' + totals["Total"].toLocaleString("en-US"));

  result.footer = footerRow;

  return result;
}